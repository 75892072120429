.leftToRight {
  animation: leftToRight 2s ease-in-out;
  animation-fill-mode: both;
}

@keyframes leftToRight {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.rightToLeft {
  animation: rightToLeft 2s ease-in-out;
  animation-fill-mode: both;
}

@keyframes rightToLeft {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
